<template>
	<div class="formulario">
        <div class="ventana">
            <div class="contenedor-dots">
                <div class="dots" v-for=" v in Object.keys(clonepaginas).length" v-bind:key="v" :class="(v == numpaso) ? 'big' : ''">
                </div>
            </div>
             <div v-if="Object.keys(clonepaginas).length >= numpaso">
                <div class="contenido-ventana" v-for="(v,k) in paginascomputed" v-bind:key="k">
                        <h1>{{v.titulo}}</h1>
                            <select v-model="selected" @change="clickelement(null)">
                                <option disabled value="">Seleccione un elemento</option>
                                <option v-for="(v2,k2) in data" @click="clickelement(null)" v-bind:value="v2.cod" v-bind:key="'op_'+k2">
                                    {{ v2.nombre }}
                                </option>
                            </select>
                </div>
            </div>
            <div  v-else>
                <div class="contenido-ventana">
                    <h1>Resumen</h1>
                    <ul v-for="n in numpaso" v-bind:key="n">
                        <li v-if="Object.keys(clonepaginas).length >= n">
                            <b>{{clonepaginas["paso"+n].titulo}}: </b> {{clonepaginas["paso"+n].valuetext}}
                        </li>
                        
                    </ul>

                </div>
            </div>
            <div class="botones">
                <button v-if="numpaso != 1" v-on:click="anterior()">Atras</button>
                <button v-on:click="cancelar()">Cancelar</button>
                <button v-if="Object.keys(clonepaginas).length >= numpaso" v-on:click="siguiente(selected)">Siguiente</button>
                <button v-if="Object.keys(clonepaginas).length < numpaso" v-on:click="guardar()">Confirmar</button>

                <!-- <button v-on:click="$emit('close')">Cancelar</button> -->
            </div>
        </div>
	</div>
</template>

<script>
import { gw } from '@/gw.js'

export default {
    mixins: [gw],
	name: 'formulario',
	props: {
        paginas: []
    },
    data: () => ({
        numpaso: 1,
        clonepaginas: [],
        data: [],
        selected: '',
        partenuevo: [],
        anteriorselected: [],

	}),
	computed:{
		paso: function(){
            return "paso"+this.numpaso;
        },
        paginascomputed: function(){
            var clonepaginas = [];

            Object.values(this.clonepaginas).forEach((v, k, i) => {
                if (k == (this.numpaso-1)) {
                    clonepaginas.push(v);
                }
            });
            return clonepaginas;
        }
	},
	mounted (){		 
        this.clonepaginas = JSON.parse(JSON.stringify(this.paginas));
        this.getData();
	},
	destroyed() {
		
	},
	methods: {
		getData(option = 'next'){
            let _this = this;
            if (Object.keys(this.clonepaginas).length >= this.numpaso ) {
                let post ={'paginas':this.clonepaginas,'paso':this.paso};
                this.api("/peticion/formulario/", post).then(
                    resp => {
                        if (resp.success == 1 && resp.datos != null) {
                            //Todo Ok
                            //si hay solamente 1 dato
                            if(resp.datos.length==1){
                                if(option=="next"){
                                    this.clickelement(resp.datos[0]);
                                    this.siguiente(resp.datos[0].cod)
                                }
                                else this.anterior()
                            }
                            _this.data = resp.datos;
                            //if(_this.clonepaginas[_this.paso].valorselecionado){
                            //    _this.selected = _this.clonepaginas[_this.paso].valorselecionado;
                            //}
                            if(_this.clonepaginas[_this.paso].value != ''){
                                _this.selected = _this.clonepaginas[_this.paso].value;
                            }
                            else{
                                _this.selected = '';
                            }
                        }else if(resp.datos == null){
                            if(option=="next"){
                                this.siguiente('');
                            }
                            else this.anterior()
                        }
                    },
                    err => {
                    console.log(err);
                    }
                );
            } else {
                
            }
        },
        siguiente(selected){
            if (this.clonepaginas[this.paso].required == "true") {
                if (selected != '' && selected != null) {
                    this.numpaso++;
                    this.getData();
                } else {
                    this.toasted(this.clonepaginas[this.paso].titulo +" no selecionado.", "error", "error");
                }
            } else {
                this.numpaso++;
                this.getData();
            }
        },
        anterior(){
            this.numpaso--;
            this.getData('back');
        },
        guardar(){
            this.$emit('guardar',this.clonepaginas);
        },
        cancelar(){
        this.clonepaginas = JSON.parse(JSON.stringify(this.paginas));
            this.$emit('close');
        },
        clickelement(select){
            if(select!=null){
                this.clonepaginas[this.paso].value = select.cod;
                this.clonepaginas[this.paso].valuetext = select.nombre;
            }else{
                for (var i = 0; i < this.data.length; i++) {
                    if (this.data[i].cod == this.selected) {
                        this.clonepaginas[this.paso].value = this.data[i].cod;
                        this.clonepaginas[this.paso].valuetext = this.data[i].nombre;
                        break;
                    }
                }
            }
        }
    },
}

</script>