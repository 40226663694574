<template>
    <v-container class="view-ordenestrabajo" grid-list-md>
        <v-layout row wrap>
            <v-flex xs12   >
        
                <!-- <v-layout justify-end>
                    <v-flex xs12>
                        <div right class="search-wrapper">
                            <input type="text" v-model="search" v-bind:placeholder="$t('ordenestrabajo_busqueda_titulo')"/>
                            <label>{{$t('ordenestrabajo_busqueda_titulo')}}</label>
                        </div>
                    </v-flex>
                </v-layout> -->
                <v-card-title style="padding: 0px 0px 0px 0px;">
                    <v-spacer class="titulo-combi-search">PARTES DE SERVICIO ({{filteredList.length}})</v-spacer>
                        
                    <v-text-field style="max-width:500px;width: 100%;"
                    v-model="search"
                    append-icon="search"
                    label="Buscar..."
                    single-line
                    hide-details
                    ></v-text-field>
                </v-card-title>

                <v-switch
                    v-model="fullorders"
                    label="Ver todos los partes"
                    color="primary"
                    style="padding: 0px 0px 10px 0px;"
                    hide-details
                    @change="data()"
                ></v-switch>

                <v-btn
                    class="btn-nuevoparte"
                    color="success"
                    @click="nuevoParte = !nuevoParte" 
                >
                <v-icon left>open_in_new</v-icon>
                Crear nuevo parte
                </v-btn>
                
                <Formulario v-if="nuevoParte" v-bind:paginas="partenuevo" v-on:close="nuevoParte=false" v-on:guardar="guardar($event)"></Formulario>
                                  
                <v-card class="xs-12 mb-3 cardlist" v-for="(item, index) in filteredList" :key="index" >
                        
                            <v-card-text>
                                <router-link  :to="`/ordentrabajo/${item.numservicio}`">
                                    <v-chip label color="#4fa272" text-color="white" class="ml-0 mr-3 mb-3">
                                        <v-icon left>label</v-icon>{{item.numservicio}}
                                    </v-chip>
                                </router-link>

                                <v-chip class="mb-3" v-if="item.fechainiprevista" label >
                                    <v-icon left>av_timer</v-icon>{{ formatDate(item.fecha) }} <span v-if="item.fecha">&nbsp;&nbsp;&nbsp;->&nbsp;&nbsp;&nbsp;</span> {{ formatDate(item.fecha) }}
                                </v-chip>

                                <div><input type="checkbox" id="checkbox" v-model="item.contratomant" true-value="t" false-value="f" disabled> Contrato de mantenimiento</div>
                                <div><input type="checkbox" id="checkbox" v-model="item.presupuesto" true-value="t" false-value="f" disabled> Presupuesto</div>

                                <!-- <h3>{{item.tipo}}</h3> -->
                                <!-- <h3>{{item.prioridad}} - {{item.tipo}}</h3> -->
                                <div class="text">
                                    <div class="mt-2"><b>CLIENTE: </b>{{item.nombrecli}} {{item.apellidoscli}} <span v-if="item.nombrecomercial">({{item.nombrecomercial}})</span></div>
                                    <div v-if="item.nombretecnico!='' && item.nombretecnico!=null"><b>TECNICO: </b>{{item.nombretecnico}} {{item.apellidostecnico}}</div>
                                    <div v-if="item.nombreagente!='' && item.nombreagente!=null"><b>AGENTE: </b>{{item.nombreagente}} {{item.apellidosagente}} <span v-if="item.porcomision">(comisión {{item.porcomision}}%)</span></div>
                                    <!-- <p class="desccorta" text-color="white">{{item.descripcion}}</p> -->

                                        <div v-if="item.descripcion && item.descripcion.length > 200" class="desccorta" stylw='font-size:8px;'
                                        @click="opendialog(item)" >
                                            {{item.descripcion.substring(0, 199)}} ... <i style="color:#4fa272;"> Leer mas </i> 
                                        </div>
                                        <div v-if="item.descripcion && item.descripcion.length <= 200" class="desclarga" stylw='font-size:8px;' text-color="white"> {{item.descripcion}} </div>
                                </div>
                            </v-card-text>
                    </v-card> 

                <v-dialog v-model="dialogdelete.active" max-width="490">
                    <v-card>
                    <v-card-title style='background-color: #4fa272; color:white;'>{{ dialogdelete.item.numservicio }}</v-card-title>
                    <v-card-text>{{dialogdelete.item.descripcion}}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click.native="closedialog()">Cerrar</v-btn>          
                    </v-card-actions>
                    </v-card>
                </v-dialog>

                </v-flex>
               <!--  <v-flex  xs12 v-else > 
                    <v-test >
                        NO HAY NINGUN PARTE
                    </v-test>
                </v-flex> -->
            </v-layout>
 
            <!-- <v-card-title>
                  
                    <v-flex aling-start>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            v-bind:label="$t('clientes_busqueda_titulo')"
                            single-line
                        ></v-text-field>
                    </v-flex>
                </v-card-title>


            <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="ordenes"
                    item-key="name"
                    class="elevation-1"
                >
                <template slot="items" slot-scope="props">
                    <tr>
                        <td>aa</td>
                    </tr>
                </template>
                <template v-slot:no-data>
                    <v-alert :value="true" color="error" icon="warning">
                        {{$t('ordenestrabajo_vacio')}}
                    </v-alert>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        {{$t('ordenestrabajo_busqueda')}} "{{ search }}" {{$t('ordenestrabajo_busqueda_vacio')}}
                    </v-alert>
                </template> 
            </v-data-table> -->


    </v-container>
</template>

<script>
import { gw } from '@/gw.js'
import Formulario from '@/components/formulario'
export default {
    mixins: [gw],
    name: 'OrdenesTrabajo',
    components: {
        Formulario
    },
    data: (vm) => ({
        ordenes:[],
        parte:[],
        numservicio: '',
        /* crear un objeto multiple */
        dialog: {
            active: false,
            item: {}
        },
       

        partenuevo: {
            paso1:{
                required: "true",
                
                titulo: "Serie", 
                id: "codserie",
                campo: "descripcion",
                sql: "getseries",
                where: "",
                value: '',
                valuetext: "",
                dafeultip: {
                    '192.168.3.10': '4',
                }
            },
            paso2:{
                required: "true",
                titulo:"Cliente",
                id: "codcliente",
                campo:"nombre",
                sql: "getclientes",
                where: "paso1",
                value:"",
                valuetext: ""

            },
            paso3:{
                required: "true",
                titulo:"Tecnico",
                id:"codtecnico",
                campo:"nombre",
                sql: "gettecnicos",
                where: "",
                value:"",
                valuetext: "",

            },
            paso4:{
                required: "false",
                titulo:"Agente",
                id:"codagente",
                campo:"nombreap",
                sql: "getagentes",
                where: "",
                value:"",
                valuetext: "",

            }/* ,
            paso5:{
                activa: "false",
                titulo:"Resumen"
            } */
        },
        fullorders: false,
        //ordenes_search:[],
        search: '',
        nuevoParte: false,
        dialogdelete: {
            active: false,
            item:{}
        },
        headers: [{
                    text: 'Lista ordenes',
                    align: 'left',
                    value: 'search'
                }]
    }), 
    mounted() {
        this.data();
        this.setBreadcrumbs([
            {
              text: 'Partes de servicios',
              disabled: true,
              href: 'ordenestrabajo',
            }
          ])
    },
    methods: {
        data(){
            let _this = this;
            let post ={'coduser': this.auth.coduser,'fullorders': this.fullorders};
            this.api("/peticion/ordenestrabajo", post).then(_return => {
                if(_return['success']=="1"){
                    this.ordenes = _return['datos'];
                    if (this.ordenes !== undefined && this.ordenes !== null) {
                        let num=0;
                        this.ordenes.forEach(function(element) {
                            _this.ordenes[num]['search'] = Object.values(element).toString().toLowerCase();
                            num++;
                        });
                    }else{
                        this.ordenes = [];
                    }
                }
                else{
                    this.ordenes = [];
                }
            });
        },
        opendialog(item){
            this.dialogdelete.active = true; 
            this.dialogdelete.item = item;
            document.getElementsByTagName("body").style = "overflow:hidden;";
        },
        closedialog(){
            this.dialogdelete.active = false;
              document.getElementsByTagName("body").style = "overflow:auto;";
        },
        checkboxvalue(val){
            return true;
        },
        guardar(partenuevo){
            this.nuevoParte = !this.nuevoParte;
            let _this = this;
            let post ={'coduser': this.auth.coduser,'partenuevo': partenuevo};
            this.api("/peticion/ordentrabajo/insert", post).then(_return => {
                if(_return['success']=="1"){
                    this.parte = _return['datos'];
                    this.numservicio = '/ordentrabajo/';
                    this.numservicio += _return['numservicio']['0']['lpad'];
                    this.toasted("Creado correctamente", "success", "check");

                } else {
                    this.parte = [];
                }
                this.$router.push({ path: this.numservicio })
            });
            
            
        },
    },
    computed: {
        filteredList() {
            if (this.ordenes) {
                return this.ordenes.filter(o => {
                    if(this.ordenes.length < 100 || this.search.length>2){
                        if(o['search'].search(this.search.toLowerCase()) != -1){
                            this.closekeyboard();
                            return this.ordenes;
                        }
                    }
                    else{
                        this.closekeyboard();
                        return this.ordenes;
                    }
                })
            } else{
                return null;
            }
        },
        
    }
}
</script>